// Load Styles

import "../scss/main.scss";
import {
  Tooltip
} from 'bootstrap';

$('[data-bs-toggle="tooltip"]').each((idx, elm) => {
  new Tooltip(elm);
});

$('#requestCheck').change(function () {
  if ($(this).is(':checked')) {
    $('#btn-request').removeClass('disabled');
  } else {
    $('#btn-request').addClass('disabled');
  }
})
//import $ from "jquery";

if ($("#checker").length) {
  const ePos = $("#checker").offset().top + $("#checker").height();
  const searchBtn = $("#floatSearch");

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();

    if (scroll > ePos) {
      searchBtn.addClass("show");
    } else {
      searchBtn.removeClass("show");
    }
  });

}
